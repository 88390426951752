a {
	color: #3183CE;
}

.back-menu-mobile .fa-arrow-left,
.back-menu-mobile,
.backToResultsListLink .fa {
	color: #3183CE !important;
}

.directory-details-top {
	background-color: #fff;
}

.directory-details-controls a {
	height: 40px;
	padding-top: 5px;
}

footer a {
	color: #fff !important;
}

.top-panel-mobile {}

.phone-link {
	color: #fff !important;
	background-color: #3183CE !important;
}

#totalRecords_container {
	background-color: #fff;
	color: #000;
	font-weight: normal;
}

#service-directory-container {
	margin-bottom: -17px;
}

#search-results {
	background-color: white;
}

.search-box-mobile,
.services-search-row,
.top-panel-mobile {
	background-color: #fff;
	background-image: url("/assets/img/header.svg");
	background-repeat: repeat-x;
	background-position: center center;
	background-size: 100%;
}

.services-search-row {
	height: 145px;
}

.search-box-mobile {
	background-size: 250%;
}

.services-search-row-main .iss-keyword-search {
    margin-right: -1px;
}

.search-box-mobile .iss-keyword-search {
    margin-bottom: -1px;
}

.services-search-row .services-search-row-auxiliary {
	border-left: none;
}

.services-search-row .services-search-row-auxiliary .btn-auxiliary {
	height: 145px;
}

.services-search-row .services-search-row-main .help-block {
	padding-top: 10px;
	color: #000;
}

.btn-auxiliary .inner {
	color: #000;
}

#leaflet-map {
	border-bottom: 1px solid #000;
}

#leaflet-map .map-info-overlay {
	background-color: #f9f8f7;
}

#leaflet-map .map-info-overlay p.map-message {
	padding: 25px 0 0;
	font-size: 19px;
	color: #333333;
}

#leaflet-map .map-info-overlay .info-container {
	color: #333333;
}

#leaflet-map .map-down-arrow {
	border-top-color: #616161;
}

.leaflet-popup-content h3,
.leaflet-popup-content strong {
	font-weight: bold;
}

.btn-primary {
	border-color: #3183CE;
	background-color: #3183CE;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
	border-color: #2b73b5;
	background-color: #2b73b5;
}

.btn-primary-dark {
	background: none;
}

.btn-primary-dark:hover {
	background: none;
}

.form-control:focus {
	border-color: #8a8d8e;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(138, 141, 142, 0.6);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(138, 141, 142, 0.6);
}


.panel-heading {
	padding: 0 !important;
	border: 0;
}

/* Add service form */
.modal-body .panel-heading {
	padding: 10px !important;
}

.modal-body .panel-heading:hover a {
	color: #fff !important;
}

.entity-details-view-link .row {
	padding: 7px 10px;
}

.panel-default > .panel-heading {
	background-color: #F7F7F7;
	background-image: url("/assets/img/header.svg");
	background-repeat: repeat-x;
	background-position: center center;
	background-size: 100%;
	border-right: 3px solid #3183CE;
	border-left: 3px solid #3183CE;
}

.panel-default > .panel-heading:hover {
	background: none;
	background-color: #3183CE !important;
}

.panel-body {

}

.green-heading {
	background-color: transparent !important;
}

.green-heading > a,
.crisis-heading > a {
	color: #000 !important;
	background: none;
}

.green-heading > a:hover,
.crisis-heading > a:hover {
	color: #fff !important;
	background: none;
}

.panel-heading.crisis-heading {
	background: none;
	background-color: #3183CE !important;
	padding: 7px 10px !important;
}


a.crisis div,
a.crisis span,
.is-desktop .crisis-heading .phone-link,
a.crisis * {
	color: white !important;
}

a.crisis .crisis-heading:hover {
	background-color: #2b73b5 !important;
}

.crisis-heading .entity-details-view-link {
	color: #fff !important;
}

.developer-credit {
	color: #fff;
	text-align: center;
}

.developer-credit a {
	color: #fff;
	text-align: center;
	position: relative;
	top: 3px;
	text-decoration: none;
}

.developer-credit .divider {
	position: relative;
	top: 6px;
	margin: 0 5px;
	color: #8A8C8D;
}

#search-sidebar {
	margin-top: 1px;
}

.directory-details-body-tabs .active > a,
.directory-details-body-tabs li,
.directory-details-body-tabs li:first-child,
.directory-details-body-tabs li:last-child,
.directory-details-body-tabs a:hover {
	border-color: #25629c !important;
	background-color: #25629c !important;
}

.directory-details-body-tabs a {
	background-color: #3183CE !important;
}

.label-round.label-success {
	border: 1px solid #25629c;
	color: #25629c;
}

#wrap > header {
	display: none;
}
@media only screen and (min-width: 768px ) {
	#wrap > header {
		display: block;
	}
}

#footer {
	height: 50px;
	padding: 16px 0 0;
	background: #000;
}

#totalRecords_container {
	padding-top: 15px;
	color: #8A8C8D;
}

header .beta {
	position: absolute;
	top: 8px;
	left: 355px;
	color: #8A8C8D;
	font-size: 1.1em;
}

.search-spinner {
	color: black;
}

/* Ask Izzy text and logo */

.text-askizzy {
	display: block;
	margin-top: 4.5px;
}

@media only screen and (min-width: 768px ) and (max-width: 1919px ) {

	.text-askizzy {
		margin-left: 330px;
	}
}

@media only screen and (min-width: 1920px ) {

	.text-askizzy {
		margin-left: 310px;
	}
}

@media only screen and (max-width: 767px ) {
	.text-askizzy {
		padding-top: 40px;
		margin-left: 40px;
	}
}

